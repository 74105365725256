import React, { useState } from 'react';

import { Button, Paper, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';

import NavigationSection from '../Navigation/NavigationSection';
import Section from './../../Section';
import SectionHeader from './../../SectionHeader';

import { useScenariosByOwner } from '../../../util/db';
import EmptyList from '../../Common/EmptyList';
import { useAuth } from './../../../util/auth';
import EditScenarioModal from './EditScenarioModal';

const useStyles = makeStyles(() => ({
    paperItems: {
        minHeight: '350px',
        padding: '32px',
    },
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridTemplateRows: 'auto',
        gridColumnGap: '8px',
        gridRowGap: '8px',
    },
    navigation: {
        gridArea: '1 / 1 / 6 / 2',
    },
    slams: {
        gridArea: '1 / 2 / 4 / 6',
    },
    table: {
        marginTop: '32px',
    },
}));

function ScenariosPage(props) {
    const auth = useAuth();
    const styles = useStyles();

    const [creatingScenario, setCreatingScenario] = useState(false);

    const { data: scenarios, status: scenariosStatus } = useScenariosByOwner(auth?.user?.uid);

    const scenariosAreEmpty = !scenarios || scenarios.length === 0;

    return (
        <Section
            bgColor={props.bgColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            <SectionHeader title={props.title} subtitle={props.subtitle} size={4} textAlign="center" />

            <div className={styles.container}>
                <div className={styles.navigation}>
                    <NavigationSection />
                </div>
                <div className={styles.slams}>
                    <Paper className={styles.paperItems}>
                        <Typography variant="h5" paddingBottom={1}>
                            All scenarios
                        </Typography>
                        <Button
                            variant="contained"
                            sx={{ flexGrow: 0, display: { xs: 'none', sm: 'block' } }}
                            component="div"
                            size="medium"
                            color="primary"
                            onClick={() => setCreatingScenario(true)}
                        >
                            Create a scenario
                        </Button>

                        {(scenariosStatus === 'loading' || scenariosAreEmpty) && (
                            <EmptyList
                                itemsStatus={scenariosStatus}
                                itemsAreEmpty={scenariosAreEmpty}
                                emptyListMessage="Nothing yet. Click the button to add scenarios."
                            />
                        )}

                        {scenariosStatus !== 'loading' && !scenariosAreEmpty && (
                            <TableContainer component={Paper} className={styles.table}>
                                <Table sx={{ minWidth: 650 }} aria-label="bugs table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Scenario title</TableCell>
                                            <TableCell align="right">Description</TableCell>
                                            <TableCell align="right">Slam assigned</TableCell>
                                            <TableCell align="right">Url</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {scenarios.map((scenario) => (
                                            <TableRow
                                                key={scenario.title}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {scenario.title}
                                                </TableCell>
                                                <TableCell align="right">{scenario.description}</TableCell>
                                                <TableCell align="right">{scenario.sessions.title}</TableCell>
                                                <TableCell align="right">{scenario.url}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Paper>
                </div>
            </div>
            {creatingScenario && <EditScenarioModal onDone={() => setCreatingScenario(false)} />}
        </Section>
    );
}

export default ScenariosPage;
