import { makeStyles } from '@mui/styles';
import React from 'react';
import NavigationSection from '../Navigation/NavigationSection';
import Section from './../../Section';
import SectionHeader from './../../SectionHeader';

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useBugsBySession, useSession } from '../../../util/db';
import { useRouter } from '../../../util/router';
import EmptyList from '../../Common/EmptyList';

const useStyles = makeStyles(() => ({
    paperItems: {
        minHeight: '350px',
        padding: '32px',
    },
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridTemplateRows: 'auto',
        gridColumnGap: '8px',
        gridRowGap: '8px',
    },
    navigation: {
        gridArea: '1 / 1 / 6 / 2',
    },
    slams: {
        gridArea: '1 / 2 / 4 / 6',
    },
    table: {
        marginTop: '32px',
    },
}));

function BugsPage(props) {
    const router = useRouter();
    const styles = useStyles();

    const dateOptions = { dateStyle: 'medium' };

    const { data: bugs, status: bugsStatus } = useBugsBySession(router.query.id);
    const { data: session } = useSession(router.query.id);

    const bugsAreEmpty = !bugs || bugs.length === 0;

    return (
        <Section
            bgColor={props.bgColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            <SectionHeader title={props.title} subtitle={props.subtitle} size={4} textAlign="center" />

            <div className={styles.container}>
                <div className={styles.navigation}>
                    <NavigationSection />
                </div>
                <div className={styles.slams}>
                    <Paper className={styles.paperItems}>
                        {/* Fix session name loading state */}
                        <Typography variant="h5" paddingBottom={1}>
                            {session?.title ? <div>{session.title}</div> : 'Session name not found, will fix'}
                        </Typography>

                        <Typography variant="p">
                            <div>
                                {bugs?.length > 0 && (
                                    <div>
                                        {/* fix plural */}
                                        <b>{bugs?.length} total issues</b>, * bugs added to Jira
                                    </div>
                                )}
                            </div>
                        </Typography>

                        {(bugsStatus === 'loading' || bugsAreEmpty) && (
                            <EmptyList
                                itemsStatus={bugsStatus}
                                itemsAreEmpty={bugsAreEmpty}
                                emptyListMessage="Nothing yet. Slam some bugs and they'll appear here"
                            />
                        )}

                        {bugsStatus !== 'loading' && !bugsAreEmpty && (
                            <TableContainer component={Paper} className={styles.table}>
                                <Table sx={{ minWidth: 650 }} aria-label="bugs table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell align="right">Basher</TableCell>
                                            <TableCell align="right">Date</TableCell>
                                            <TableCell align="right">Priority</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {bugs.map((bug) => (
                                            <TableRow
                                                key={bug.title}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {bug.title}
                                                </TableCell>
                                                <TableCell align="right">{bug.testers.name}</TableCell>
                                                <TableCell align="right">
                                                    {Intl.DateTimeFormat('en-US', dateOptions).format(
                                                        Date.parse(bug.createdAt),
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">{bug.priority}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Paper>
                </div>
            </div>
        </Section>
    );
}

export default BugsPage;
