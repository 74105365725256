import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import Container from '@mui/material/Container';
import LinkMui from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Link } from './../util/router';
import { useDarkMode } from './../util/theme';
import Section from './Section';

const useStyles = makeStyles((theme) => ({
    sticky: {
        marginTop: 'auto',
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    item: {
        display: 'flex',
        flex: 'none',
        justifyContent: 'center',
        width: '100%',
        marginBottom: 24,
        [theme.breakpoints.up('sm')]: {
            flex: '50%',
        },
    },
    brand: {
        display: 'block',
        height: 32,
    },
    social: {
        alignItems: 'flex-end',
    },
    link: {
        color: 'inherit',
        lineHeight: 1,
        '&:not(:last-of-type)': {
            marginRight: '1.2rem',
        },
    },
    left: {
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-start',
        },
    },
    right: {
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-end',
        },
    },
    // Move links to end (bottom right)
    // Swaps position with social
    smallLinks: {
        [theme.breakpoints.up('sm')]: {
            order: 1,
        },
    },
    legal: {
        opacity: 0.6,
        fontSize: '0.875rem',
        '& a': {
            color: 'inherit',
            marginLeft: '0.8rem',
        },
    },
}));

function Footer(props) {
    const styles = useStyles();

    const darkMode = useDarkMode();
    // Use inverted logo if specified
    // and we are in dark mode
    const logo = props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

    return (
        <Section
            bgColor={props.bgColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
            className={props.sticky && styles.sticky}
        >
            <Container>
                <div className={styles.wrapper}>
                    <div className={`${styles.item} ${styles.left}`}>
                        <Link to="/">
                            <img src={logo} alt="Logo" className={styles.brand} />
                        </Link>
                    </div>
                    <div className={`${styles.item} ${styles.right} ${styles.smallLinks}`}>
                        <Typography>
                            <LinkMui component={Link} to="/about" className={styles.link}>
                                About update pls
                            </LinkMui>
                            <LinkMui component={Link} to="/faq" className={styles.link}>
                                FAQ update pls
                            </LinkMui>
                            <LinkMui component={Link} to="/contact" className={styles.link}>
                                Contact update pls
                            </LinkMui>
                            <LinkMui href="https://medium.com" target="_blank" rel="noreferrer" className={styles.link}>
                                Blog update pls
                            </LinkMui>
                        </Typography>
                    </div>
                    <div className={`${styles.item} ${styles.right} ${styles.social}`}>
                        <a href="https://twitter.com/divjoy" target="_blank" rel="noreferrer" className={styles.link}>
                            <TwitterIcon fontSize="small" />
                        </a>
                        <a
                            href="https://facebook.com/DivjoyOfficial"
                            target="_blank"
                            rel="noreferrer"
                            className={styles.link}
                        >
                            <FacebookIcon fontSize="small" />
                        </a>
                        <a href="https://instagram.com" target="_blank" rel="noreferrer" className={styles.link}>
                            <InstagramIcon fontSize="small" />
                        </a>
                    </div>
                    <span className={`${styles.item} ${styles.legal} ${styles.left}`}>
                        {props.copyright}
                        <LinkMui component={Link} to="/legal/terms-of-service">
                            Terms update pls
                        </LinkMui>
                        <LinkMui component={Link} to="/legal/privacy-policy">
                            Privacy update pls
                        </LinkMui>
                    </span>
                </div>
            </Container>
        </Section>
    );
}

export default Footer;
