import React from 'react';
import { useRouter } from '../util/router';
import Meta from './../components/Meta';
import SessionSection from './../components/SessionSection';
import { requireAuth } from './../util/auth';
import { useSession } from './../util/db';

function SessionPage() {
    const router = useRouter();
    const { data: session } = useSession(router.query.id);

    if (!session) {
        return null;
    }

    return (
        <React.Fragment>
            <Meta title={session.title} />
            <SessionSection
                bgColor="default"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                title={session.title}
                subtitle={`Visit bugslam.link/${session.shortcode} on your testing device to join the session`}
            />
        </React.Fragment>
    );
}

export default requireAuth(SessionPage);
