import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import React from 'react';
import Section from './Section';

function PageLoader(props) {
    const { height = 350 } = props;

    return (
        <Section bgColor="default">
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" height={height}>
                    {!props.children && <CircularProgress size={32} />}

                    {props.children && <React.Fragment>{props.children}</React.Fragment>}
                </Box>
            </Container>
        </Section>
    );
}

export default PageLoader;
