import React from 'react';
import Summary from './../components/Dashboard/Summary/Summary';
import Meta from './../components/Meta';
import { requireAuth } from './../util/auth';

function MainPage() {
    return (
        <React.Fragment>
            <Meta title="Bug slam" />
            <Summary />
        </React.Fragment>
    );
}

export default requireAuth(MainPage);
