import DeleteIcon from '@mui/icons-material/Delete';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import Alert from '@mui/lab/Alert';
import { ListItemButton, ListItemIcon } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LinkMui from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useAuth } from './../util/auth';
import { deleteSession, useSessionsByOwner } from './../util/db';
import { Link } from './../util/router';
import EditItemModal from './EditItemModal';

const useStyles = makeStyles((theme) => ({
    paperItems: {
        minHeight: '300px',
    },
    featured: {
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.action.selected : '#fdf8c2',
    },
    starFeatured: {
        color: theme.palette.warning.main,
    },
}));

function DashboardItems() {
    const styles = useStyles();

    const auth = useAuth();

    const { data: items, status: itemsStatus, error: itemsError } = useSessionsByOwner(auth.user.uid);

    const [creatingItem, setCreatingItem] = useState(false);

    const [updatingItemId, setUpdatingItemId] = useState(null);

    const itemsAreEmpty = !items || items.length === 0;

    return (
        <React.Fragment>
            {itemsError && (
                <Box mb={3}>
                    <Alert severity="error">{itemsError.message}</Alert>
                </Box>
            )}

            <Paper className={styles.paperItems}>
                <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
                    <Typography variant="h5">Bug slam</Typography>
                    <Button variant="contained" size="medium" color="primary" onClick={() => setCreatingItem(true)}>
                        New session
                    </Button>
                </Box>
                <Typography padding={2} variant="h5">
                    Bug slam
                </Typography>

                <Divider />

                <List>
                    <ListItem key={'Overview'} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>{<InboxIcon />}</ListItemIcon>
                            <ListItemText primary={'Overview'} />
                        </ListItemButton>
                    </ListItem>
                </List>

                {(itemsStatus === 'loading' || itemsAreEmpty) && (
                    <Box py={5} px={3} align="center">
                        {itemsStatus === 'loading' && <CircularProgress size={32} />}

                        {itemsStatus !== 'loading' && itemsAreEmpty && (
                            <React.Fragment>Nothing yet. Click the button to add your first item.</React.Fragment>
                        )}
                    </Box>
                )}

                {itemsStatus !== 'loading' && items && items.length > 0 && (
                    <List disablePadding={true}>
                        {items.map((item, index) => (
                            <ListItem
                                key={index}
                                divider={index !== items.length - 1}
                                className={item.featured ? styles.featured : ''}
                            >
                                <ListItemText>
                                    <LinkMui component={Link} to={`/sessions/${item.id}`}>
                                        {item.name}
                                    </LinkMui>
                                </ListItemText>
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => deleteSession(item.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                )}
            </Paper>

            {creatingItem && <EditItemModal onDone={() => setCreatingItem(false)} />}

            {updatingItemId && <EditItemModal id={updatingItemId} onDone={() => setUpdatingItemId(null)} />}
        </React.Fragment>
    );
}

export default DashboardItems;
