import Alert from '@mui/lab/Alert';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from './../util/auth';
import { createSession, updateSession, useSession } from './../util/db';

const useStyles = makeStyles(() => ({
    content: {
        paddingBottom: 24,
    },
}));

function EditItemModal(props) {
    const styles = useStyles();

    const auth = useAuth();
    const [pending, setPending] = useState(false);
    const [formAlert, setFormAlert] = useState(null);

    const { register, handleSubmit, errors } = useForm();

    // This will fetch item if props.id is defined
    // Otherwise query does nothing and we assume
    // we are creating a new item.
    const { data: itemData, status: itemStatus } = useSession(props.id);

    // If we are updating an existing item
    // don't show modal until item data is fetched.
    if (props.id && itemStatus !== 'success') {
        return null;
    }

    const onSubmit = (data) => {
        setPending(true);

        const query = props.id ? updateSession(props.id, data) : createSession({ owner: auth.user.uid, ...data });

        query
            .then(() => {
                // Let parent know we're done so they can hide modal
                props.onDone();
            })
            .catch((error) => {
                // Hide pending indicator
                setPending(false);
                // Show error alert message
                setFormAlert({
                    type: 'error',
                    message: error.message,
                });
            });
    };

    return (
        <Dialog open={true} onClose={props.onDone}>
            <DialogTitle>
                {props.id && <React.Fragment>Update</React.Fragment>}
                {!props.id && (
                    <React.Fragment>
                        <Typography variant="h5" fontSize={24} fontWeight="bold">
                            Create slam
                        </Typography>
                        <Typography variant="p" color="#868585" fontSize={14}>
                            Add the essentials to create your bug slam
                        </Typography>
                    </React.Fragment>
                )}
            </DialogTitle>
            <DialogContent className={styles.content}>
                {formAlert && (
                    <Box mb={4}>
                        <Alert severity={formAlert.type}>{formAlert.message}</Alert>
                    </Box>
                )}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label="Title"
                                name="title"
                                defaultValue={itemData && itemData.title}
                                error={errors.title ? true : false}
                                helperText={errors.title && errors.title.message}
                                fullWidth={true}
                                autoFocus={true}
                                inputRef={register({
                                    required: 'Please enter a title',
                                })}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label="Description"
                                name="description"
                                defaultValue={itemData && itemData.description}
                                error={errors.description ? true : false}
                                helperText={errors.description && errors.description.message}
                                fullWidth={true}
                                autoFocus={true}
                                inputRef={register({
                                    required: 'Please enter session description',
                                })}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <TextField
                                variant="outlined"
                                type="datetime-local"
                                name="createdAt"
                                defaultValue={itemData && itemData.createdAt}
                                error={errors.createdAt ? true : false}
                                helperText={errors.createdAt && errors.createdAt.message}
                                fullWidth={true}
                                autoFocus={true}
                                inputRef={register({
                                    required: 'Please enter when session starts',
                                })}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Button variant="contained" color="primary" size="large" type="submit" disabled={pending}>
                                {!pending && <span>Save</span>}

                                {pending && <CircularProgress size={28} />}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
}

export default EditItemModal;
