import InboxIcon from '@mui/icons-material/MoveToInbox';
import { Divider, ListItemButton, ListItemIcon, SvgIcon } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { Link } from '../../../util/router';
import EditItemModal from '../../EditItemModal';
import { ReactComponent as logoIcon } from './../../../static/logo.svg';

const useStyles = makeStyles((theme) => ({
    paperItems: {
        minHeight: '900px',
    },
    featured: {
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.action.selected : '#fdf8c2',
    },
    starFeatured: {
        color: theme.palette.warning.main,
    },
    nameContainer: {
        display: 'flex',
    },
    name: {
        marginLeft: '16px',
    },
    listItemIcon: {
        minWidth: '32px',
    },
    listItemText: {
        color: 'black',
    },
}));

function NavigationItems() {
    const styles = useStyles();

    const [creatingItem, setCreatingItem] = useState(false);

    const [updatingItemId, setUpdatingItemId] = useState(null);

    return (
        <React.Fragment>
            <Paper className={styles.paperItems}>
                <Typography padding={2} variant="h5" className={styles.nameContainer}>
                    <SvgIcon component={logoIcon} viewBox={'0 0 18 18'} />
                    <div className={styles.name}>Bug slam</div>
                </Typography>
                <Divider />
                <List>
                    <ListItem
                        key={'Overview'}
                        disablePadding
                        component={Link}
                        className={styles.listItemText}
                        to="/main"
                    >
                        <ListItemButton>
                            <ListItemIcon className={styles.listItemIcon}>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Overview'} />
                        </ListItemButton>
                    </ListItem>

                    <ListItem
                        key={'Scenarios'}
                        disablePadding
                        component={Link}
                        className={styles.listItemText}
                        to="/scenarios"
                    >
                        <ListItemButton>
                            <ListItemIcon className={styles.listItemIcon}>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Scenarios'} />
                        </ListItemButton>
                    </ListItem>

                    <ListItem key={'All slams'} disablePadding>
                        <ListItemButton>
                            <ListItemIcon className={styles.listItemIcon}>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary={'All slams'} />
                        </ListItemButton>
                    </ListItem>

                    <ListItem key={'Progress report'} disablePadding>
                        <ListItemButton>
                            <ListItemIcon className={styles.listItemIcon}>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Progress report'} />
                        </ListItemButton>
                    </ListItem>

                    <ListItem key={'Settings'} disablePadding>
                        <ListItemButton>
                            <ListItemIcon className={styles.listItemIcon}>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Settings'} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Paper>

            {creatingItem && <EditItemModal onDone={() => setCreatingItem(false)} />}

            {updatingItemId && <EditItemModal id={updatingItemId} onDone={() => setUpdatingItemId(null)} />}
        </React.Fragment>
    );
}

export default NavigationItems;
