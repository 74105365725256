import Alert from '@mui/lab/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import React from 'react';
import { useAuth } from './../../../util/auth';
import { useRouter } from './../../../util/router';
import Section from './../../Section';
import NavigationItems from './NavigationItems';

function NavigationSection(props) {
    const auth = useAuth();
    const router = useRouter();

    return (
        <Section
            paddingTop={'0px !important'}
            bgColor={props.bgColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
            minWidth={300}
        >
            <Container>
                {router.query.paid && auth.user.planIsActive && (
                    <Box mx="auto" mb={4} maxWidth={400}>
                        <Alert severity="success">
                            You are now subscribed to the {auth.user.planId} plan
                            <span role="img" aria-label="party" style={{ marginLeft: '10px' }}>
                                🥳
                            </span>
                        </Alert>
                    </Box>
                )}
                <NavigationItems />
            </Container>
        </Section>
    );
}

export default NavigationSection;
