import Alert from '@mui/lab/Alert';
import { Button, Divider, Paper, Switch, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import LinkMui from '@mui/material/Link';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useAuth } from './../../../util/auth';
import { updateSession, useSessionsByOwner } from './../../../util/db';

import EmptyList from '../../Common/EmptyList';
import EditItemModal from '../../EditItemModal';
import { Link, useRouter } from './../../../util/router';
import Section from './../../Section';

const useStyles = makeStyles(() => ({
    paperItems: {
        minHeight: '350px',
        padding: '32px',
    },
}));

function Slams(props) {
    const auth = useAuth();
    const router = useRouter();
    const styles = useStyles();

    const [creatingItem, setCreatingItem] = useState(false);

    const updateSlamStatus = (event, item) => {
        const finishedAt = event.target.checked ? null : new Date();
        updateSession(item.id, {
            id: item.id,
            owner: item.owner,
            shortcode: item.shortcode,
            title: item.title,
            description: item.description,
            finishedAt,
            active: event.target.checked,
        });
    };

    const { data: items, status: itemsStatus, error: itemsError } = useSessionsByOwner(auth.user.uid);

    const dateOptions = { dateStyle: 'medium' };

    const itemsAreEmpty = !items || items.length === 0;

    return (
        <Section
            paddingTop={'0px !important'}
            bgColor={props.bgColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
            maxWidth={1000}
        >
            {router.query.paid && auth.user.planIsActive && (
                <Box mx="auto" mb={4} maxWidth={400}>
                    <Alert severity="success">
                        You are now subscribed to the {auth.user.planId} plan
                        <span role="img" aria-label="party" style={{ marginLeft: '10px' }}>
                            🥳
                        </span>
                    </Alert>
                </Box>
            )}
            {itemsError && (
                <Box mb={3}>
                    <Alert severity="error">{itemsError.message}</Alert>
                </Box>
            )}
            <Paper className={styles.paperItems}>
                <Typography variant="h5" paddingBottom={1}>
                    <div> All slams </div>
                </Typography>
                <Button
                    variant="contained"
                    sx={{ flexGrow: 0, display: { xs: 'none', sm: 'block' } }}
                    component="div"
                    size="medium"
                    color="primary"
                    onClick={() => setCreatingItem(true)}
                >
                    Create a slam
                </Button>
                <Typography variant="p">Open a slam to review issues raised and export bugs to Jira</Typography>
                <Divider />

                {(itemsStatus === 'loading' || itemsAreEmpty) && (
                    <EmptyList
                        itemsStatus={itemsStatus}
                        itemsAreEmpty={itemsAreEmpty}
                        emptyListMessage="Nothing yet. Click the button to add your first item."
                    />
                )}

                {itemsStatus !== 'loading' && items && items.length > 0 && (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">Date created</TableCell>
                                    <TableCell align="right">No. of issues</TableCell>
                                    <TableCell align="right">End date</TableCell>
                                    <TableCell align="right">Active</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.map((slam) => (
                                    <TableRow
                                        key={slam.title}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <LinkMui component={Link} to={`/slam/${slam.id}`}>
                                                {slam.title}
                                            </LinkMui>
                                        </TableCell>
                                        <TableCell align="right">
                                            {Intl.DateTimeFormat('en-US', dateOptions).format(
                                                Date.parse(slam.createdAt),
                                            )}
                                        </TableCell>
                                        <TableCell align="right">{slam.bugs[0].count}</TableCell>
                                        <TableCell align="right">
                                            {slam.finishedAt
                                                ? Intl.DateTimeFormat('en-US', dateOptions).format(
                                                      Date.parse(slam.finishedAt),
                                                  )
                                                : ''}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Switch
                                                checked={slam.active}
                                                onChange={(event) => updateSlamStatus(event, slam)}
                                            ></Switch>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Paper>
            {creatingItem && <EditItemModal onDone={() => setCreatingItem(false)} />}
        </Section>
    );
}

export default Slams;
