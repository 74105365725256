import React from 'react';
import DashboardSection from './../components/DashboardSection';
import Meta from './../components/Meta';
import { requireAuth } from './../util/auth';

function DashboardPage() {
    return (
        <React.Fragment>
            <Meta title="Dashboard" />
            <DashboardSection
                bgColor="default"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                title="Dashboard"
                subtitle=""
            />
        </React.Fragment>
    );
}

export default requireAuth(DashboardPage);
