import Alert from '@mui/lab/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import React from 'react';
import { useAuth } from './../util/auth';
import { useRouter } from './../util/router';
import DashboardItems from './DashboardItems';
import Section from './Section';

function DashboardSection(props) {
    const auth = useAuth();
    const router = useRouter();

    return (
        <Section
            bgColor={props.bgColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            <Container>
                {router.query.paid && auth.user.planIsActive && (
                    <Box mx="auto" mb={4} maxWidth={400}>
                        <Alert severity="success">
                            You are now subscribed to the {auth.user.planId} plan
                            <span role="img" aria-label="party" style={{ marginLeft: '10px' }}>
                                🥳
                            </span>
                        </Alert>
                    </Box>
                )}

                <DashboardItems />
            </Container>
        </Section>
    );
}

export default DashboardSection;
