import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useRouter } from '../util/router';
import { useBugsBySession } from './../util/db';
import Section from './Section';
import SectionHeader from './SectionHeader';

const useStyles = makeStyles((theme) => ({
    accordion: {
        // Remove shadow
        boxShadow: 'none',
        '&:before': {
            // Remove default divider
            display: 'none',
        },
        // Add a custom border
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
    },
    expanded: {
        margin: `0 !important`,
    },
    summary: {
        minHeight: 78,
    },
    summaryContent: {
        margin: '0 !important',
    },
}));

function SessionItems(props) {
    const styles = useStyles();
    const router = useRouter();

    const { data: bugs } = useBugsBySession(router.query.id);

    if (!bugs) {
        return null;
    }

    return (
        <Section
            bgColor={props.bgColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            <Container maxWidth="md">
                <SectionHeader title={props.title} subtitle={props.subtitle} size={4} textAlign="center" />

                {bugs.map((bug, index) => (
                    <Accordion
                        classes={{
                            root: styles.accordion,
                            expanded: styles.expanded,
                        }}
                        key={index}
                    >
                        <AccordionSummary
                            classes={{
                                root: styles.summary,
                                content: styles.summaryContent,
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`bugs-panel-${index}`}
                        >
                            <Typography variant="h6">{bug.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails id={`bugs-panel-${index}`}>
                            <Typography>{bug.description}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Container>
        </Section>
    );
}

export default SessionItems;
