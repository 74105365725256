import Alert from '@mui/lab/Alert';
import { InputLabel, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../util/auth';
import { createScenario, updateScenario, useSessionsByOwner } from '../../../util/db';

const useStyles = makeStyles(() => ({
    content: {
        paddingBottom: 24,
    },
}));

function EditScenarioModal(props) {
    const styles = useStyles();

    const auth = useAuth();
    const [selectedSession, setSelectedSession] = useState(null);

    const [pending, setPending] = useState(false);
    const [formAlert, setFormAlert] = useState(null);

    const { data: sessions } = useSessionsByOwner(auth.user.uid);

    const { register, handleSubmit, errors } = useForm();

    const { scenario } = props;

    // This will fetch item if props.id is defined
    // Otherwise query does nothing and we assume
    // we are creating a new item.
    // const { data: itemData, status: itemStatus } = useSession(props.id);

    // If we are updating an existing item
    // don't show modal until item data is fetched.
    // if (props.id && itemStatus !== 'success') {
    //     return null;
    // }

    const onSubmit = (data) => {
        setPending(true);

        // not ideal merging of data and selected session, somehow it should be possible to get that into form state
        const query = props.scenario
            ? updateScenario(props.scenario.id, { ...data, session: selectedSession })
            : createScenario({ owner: auth.user.uid, session: selectedSession, ...data });

        query
            .then(() => {
                // Let parent know we're done so they can hide modal
                props.onDone();
            })
            .catch((error) => {
                // Hide pending indicator
                setPending(false);
                // Show error alert message
                setFormAlert({
                    type: 'error',
                    message: error.message,
                });
            });
    };

    const handleSelectorChange = (event) => {
        setSelectedSession(event.target.value);
    };

    return (
        <Dialog open={true} onClose={props.onDone}>
            <DialogTitle>
                {props.scenario && <React.Fragment>Update</React.Fragment>}
                {!props.scenario && (
                    <React.Fragment>
                        <Typography variant="h5" fontSize={24} fontWeight="bold">
                            Create scenario
                        </Typography>
                        <Typography variant="p" color="#868585" fontSize={14}>
                            Add the essentials to create a scenario
                        </Typography>
                    </React.Fragment>
                )}
            </DialogTitle>
            <DialogContent className={styles.content}>
                {formAlert && (
                    <Box mb={4}>
                        <Alert severity={formAlert.type}>{formAlert.message}</Alert>
                    </Box>
                )}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label="Title"
                                name="title"
                                defaultValue={scenario && scenario.title}
                                error={errors.title ? true : false}
                                helperText={errors.title && errors.title.message}
                                fullWidth={true}
                                autoFocus={true}
                                inputRef={register({
                                    required: 'Please enter a title',
                                })}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label="Description"
                                name="description"
                                defaultValue={scenario && scenario.description}
                                error={errors.description ? true : false}
                                helperText={errors.description && errors.description.message}
                                fullWidth={true}
                                autoFocus={true}
                                inputRef={register({
                                    required: 'Please enter scenario description',
                                })}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label="Url"
                                name="url"
                                defaultValue={scenario && scenario.url}
                                error={errors.url ? true : false}
                                helperText={errors.url && errors.url.message}
                                fullWidth={true}
                                autoFocus={true}
                                inputRef={register({
                                    required: 'Please enter scenario url',
                                })}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <InputLabel id="select-session">Sessions</InputLabel>
                            <Select
                                fullWidth={true}
                                labelId="select-session"
                                value={selectedSession}
                                label="Sessions"
                                onChange={handleSelectorChange}
                            >
                                {sessions?.map((session) => (
                                    <MenuItem key={session.id} value={session.id}>
                                        {session.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Button variant="contained" color="primary" size="large" type="submit" disabled={pending}>
                                {!pending && <span>Save</span>}

                                {pending && <CircularProgress size={28} />}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
}

export default EditScenarioModal;
