import Container from '@mui/material/Container';
import React from 'react';
import Section from './Section';
import SectionHeader from './SectionHeader';
import SessionItems from './SessionItems';

function DashboardSection(props) {
    return (
        <Section
            bgColor={props.bgColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            <Container>
                <SectionHeader title={props.title} subtitle={props.subtitle} size={4} textAlign="center" />

                <SessionItems />
            </Container>
        </Section>
    );
}

export default DashboardSection;
