import Alert from '@mui/lab/Alert';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { useRouter } from './../util/router';
import AuthForm from './AuthForm';
import AuthSocial from './AuthSocial';

function Auth(props) {
    const router = useRouter();
    const [formAlert, setFormAlert] = useState(null);

    const handleAuth = () => {
        router.push(props.afterAuthPath);
    };

    const handleFormAlert = (data) => {
        setFormAlert(data);
    };

    return (
        <React.Fragment>
            {formAlert && (
                <Box mb={3}>
                    <Alert severity={formAlert.type}>{formAlert.message}</Alert>
                </Box>
            )}

            <AuthForm
                type={props.type}
                buttonAction={props.buttonAction}
                onAuth={handleAuth}
                onFormAlert={handleFormAlert}
            />

            {['signup', 'signin'].includes(props.type) && (
                <React.Fragment>
                    {props.providers && props.providers.length && (
                        <React.Fragment>
                            <Box textAlign="center" fontSize={12} my={2}>
                                OR
                            </Box>
                            <AuthSocial
                                buttonAction={props.buttonAction}
                                providers={props.providers}
                                showLastUsed={true}
                                onAuth={handleAuth}
                                onError={(message) => {
                                    handleFormAlert({
                                        type: 'error',
                                        message: message,
                                    });
                                }}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default Auth;
