import React from 'react';

import BugsPage from '../components/Dashboard/Bugs/Bugs';
import ScenariosPage from '../components/Dashboard/Scenarios/Scenarios';
import Footer from './../components/Footer';
import { AuthProvider } from './../util/auth';
import { QueryClientProvider } from './../util/db';
import { Redirect, Route, Router, Switch } from './../util/router';
import { ThemeProvider } from './../util/theme';
import NotFoundPage from './404';
import AuthPage from './auth';
import DashboardPage from './dashboard';
import MainPage from './main';
import SessionPage from './session';
import SettingsPage from './settings';

function App() {
    return (
        <QueryClientProvider>
            <ThemeProvider>
                <AuthProvider>
                    <Router>
                        <React.Fragment>
                            <Switch>
                                <Route exact path="/">
                                    <Redirect to="/main" />
                                </Route>

                                <Route exact path="/main" component={MainPage} />
                                <Route exact path="/scenarios" component={ScenariosPage} />
                                <Route exact path="/slam/:id" component={BugsPage} />

                                <Route exact path="/dashboard" component={DashboardPage} />

                                <Route exact path="/sessions/:id" component={SessionPage} />

                                <Route exact path="/auth/:type" component={AuthPage} />

                                <Route exact path="/settings/:section" component={SettingsPage} />

                                <Route component={NotFoundPage} />
                            </Switch>

                            <Footer
                                bgColor="light"
                                size="normal"
                                bgImage=""
                                bgImageOpacity={1}
                                description="we have to update this"
                                copyright={`© ${new Date().getFullYear()} Bugslam`}
                                logo="https://uploads.divjoy.com/logo.svg"
                                logoInverted="https://uploads.divjoy.com/logo-white.svg"
                                sticky={true}
                            />
                        </React.Fragment>
                    </Router>
                </AuthProvider>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
