import { QueryClient, QueryClientProvider as QueryClientProviderBase, useQuery } from 'react-query';
import supabase from './supabase';

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Fetch user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
    // Manage data fetching with React Query: https://react-query.tanstack.com/overview
    return useQuery(
        // Unique query key: https://react-query.tanstack.com/guides/query-keys
        ['user', { uid }],
        // Query function that fetches data
        () => supabase.from('users').select(`*`).eq('id', uid).single().then(handle),
        // Only call query function if we have a `uid`
        { enabled: !!uid },
    );
}

// Fetch user data (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
    return supabase.from('users').select().eq('id', uid).single().then(handle);
}

// Update an existing user
export async function updateUser(uid, data) {
    const response = await supabase.from('users').update(data).eq('id', uid).then(handle);
    // Invalidate and refetch queries that could have old data
    await client.invalidateQueries(['user', { uid }]);
    return response;
}

/**** SESSIONS ****/

// Fetch session data
export function useSession(id) {
    return useQuery(['session', { id }], () => supabase.from('sessions').select().eq('id', id).single().then(handle), {
        enabled: !!id,
    });
}

// Fetch all sessions by owner
export function useSessionsByOwner(owner) {
    return useQuery(
        ['sessions', { owner }],
        () =>
            supabase
                .from('sessions')
                .select('*, bugs(count)')
                .eq('owner', owner)
                .order('createdAt', { ascending: false })
                .then(handle),
        { enabled: !!owner },
    );
}

// Create new session
export async function createSession(data) {
    const response = await supabase.from('sessions').insert([data]).then(handle);
    // Invalidate and refetch queries that could have old data
    await client.invalidateQueries(['sessions']);
    return response;
}

// Update session
export async function updateSession(id, data) {
    const response = await supabase.from('sessions').update(data).eq('id', id).then(handle);
    // Invalidate and refetch queries that could have old data
    await Promise.all([client.invalidateQueries(['session', { id }]), client.invalidateQueries(['sessions'])]);
    return response;
}

// Delete session
export async function deleteSession(id) {
    const response = await supabase.from('sessions').delete().eq('id', id).then(handle);
    // Invalidate and refetch queries that could have old data
    await Promise.all([client.invalidateQueries(['session', { id }]), client.invalidateQueries(['sessions'])]);
    return response;
}

// Fetch all bugs by session
export function useBugsBySession(id) {
    return useQuery(
        ['bugs', { session: id }],
        () =>
            supabase
                .from('bugs')
                .select('*, testers(name)')
                .eq('session', id)
                .order('createdAt', { ascending: false })
                .then(handle),
        { enabled: !!id },
    );
}

// Fetch all scenarios by owner
export function useScenariosByOwner(owner) {
    return useQuery(
        ['scenarios', { owner }],
        () => supabase.from('scenarios').select('*, sessions(title)').eq('owner', owner).then(handle),
        { enabled: !!owner },
    );
}

// Create new scenario
export async function createScenario(data) {
    const response = await supabase.from('scenarios').insert([data]).then(handle);
    // Invalidate and refetch queries that could have old data
    await client.invalidateQueries(['scenarios']);
    return response;
}

// Update scenario
export async function updateScenario(id, data) {
    const response = await supabase.from('scenarios').update(data).eq('id', id).then(handle);
    // Invalidate and refetch queries that could have old data
    await Promise.all([client.invalidateQueries(['scenarios', { id }]), client.invalidateQueries(['scenarios'])]);
    return response;
}

/**** HELPERS ****/

// Get response data or throw error if there is one
function handle(response) {
    if (response.error) throw response.error;
    return response.data;
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
    // eslint-disable-next-line react/react-in-jsx-scope
    return <QueryClientProviderBase client={client}>{props.children}</QueryClientProviderBase>;
}
