import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import React, {Fragment} from 'react';

function EmptyList(props) {
    const { itemsStatus, itemsAreEmpty, emptyListMessage } = props;

    return (
        <Box py={5} px={3} align="center">
            {itemsStatus === 'loading' && <CircularProgress size={32} />}

            {itemsStatus !== 'loading' && itemsAreEmpty && <Fragment>{emptyListMessage}</Fragment>}
        </Box>
    );
}

export default EmptyList;
