import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
    root: {
        content: '',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        zIndex: 0,
    },
}));

function BackgroundImage(props) {
    const styles = useStyles();

    const { image, opacity, ...otherProps } = props;

    return (
        <div
            className={styles.root}
            style={{
                backgroundImage: `url(${image})`,
                opacity: opacity,
            }}
            {...otherProps}
        />
    );
}

export default BackgroundImage;
