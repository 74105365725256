import React from 'react';
import Meta from './../components/Meta';
import SettingsSection from './../components/SettingsSection';
import { requireAuth } from './../util/auth';
import { useRouter } from './../util/router';

function SettingsPage() {
    const router = useRouter();

    return (
        <React.Fragment>
            <Meta title="Settings" />
            <SettingsSection
                bgColor="default"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                section={router.query.section}
                key={router.query.section}
            />
        </React.Fragment>
    );
}

export default requireAuth(SettingsPage);
