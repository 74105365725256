import React from 'react';
import AuthSection from './../components/AuthSection';
import Meta from './../components/Meta';
import { useRouter } from './../util/router';

function AuthPage() {
    const router = useRouter();

    return (
        <React.Fragment>
            <Meta title="Auth" />
            <AuthSection
                bgColor="default"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                type={router.query.type}
                providers={['google', 'facebook', 'twitter']}
                afterAuthPath={router.query.next || '/dashboard'}
            />
        </React.Fragment>
    );
}

export default AuthPage;
