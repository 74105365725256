import Alert from '@mui/lab/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from './../util/auth';
import AuthSocial from './AuthSocial';

const useStyles = makeStyles(() => ({
    content: {
        paddingBottom: 24,
    },
}));

function ReauthModal(props) {
    const styles = useStyles();

    const auth = useAuth();
    const [pending, setPending] = useState(false);
    const [formAlert, setFormAlert] = useState(null);

    const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data) => {
        const { pass } = data;
        setPending(true);

        auth.signin(auth.user.email, pass)
            .then(() => {
                // Call failed action that originally required reauth
                props.callback();
                // Let parent know we're done so they can hide modal
                props.onDone();
            })
            .catch((error) => {
                // Hide pending indicator
                setPending(false);
                // Show error alert message
                setFormAlert({
                    type: 'error',
                    message: error.message,
                });
            });
    };

    return (
        <Dialog open={true} onClose={props.onDone}>
            <DialogTitle>Please sign in again to complete this action</DialogTitle>
            <DialogContent className={styles.content}>
                {formAlert && (
                    <Box mb={4}>
                        <Alert severity={formAlert.type}>{formAlert.message}</Alert>
                    </Box>
                )}

                {props.provider === 'password' && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container={true} spacing={2}>
                            <Grid item={true} xs={12}>
                                <TextField
                                    variant="outlined"
                                    type="password"
                                    label="Password"
                                    name="pass"
                                    error={errors.pass ? true : false}
                                    helperText={errors.pass && errors.pass.message}
                                    fullWidth={true}
                                    autoFocus={true}
                                    inputRef={register({
                                        required: 'Please enter your password',
                                    })}
                                />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    disabled={pending}
                                    fullWidth={true}
                                >
                                    {!pending && <span>Submit</span>}

                                    {pending && <CircularProgress size={28} />}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}

                {props.provider !== 'password' && (
                    <AuthSocial
                        type="signin"
                        buttonText="Sign in"
                        providers={[props.provider]}
                        showLastUsed={false}
                        onAuth={() => {
                            props.callback();
                            props.onDone();
                        }}
                        onError={(message) => {
                            setFormAlert({
                                type: 'error',
                                message: message,
                            });
                        }}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
}

export default ReauthModal;
