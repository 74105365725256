import Alert from '@mui/lab/Alert';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import React from 'react';
import NavigationSection from '../Navigation/NavigationSection';
import Slams from '../Slams/Slams';
import { useAuth } from './../../../util/auth';
import { useRouter } from './../../../util/router';
import Section from './../../Section';

const useStyles = makeStyles(() => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridTemplateRows: 'auto',
        gridColumnGap: '8px',
        gridRowGap: '8px',
    },
    navigation: {
        gridArea: '1 / 1 / 6 / 2',
    },
    slams: {
        gridArea: '1 / 2 / 4 / 6',
    },
    progress: {
        height: '200px',
        width: '420px',
        backgroundColor: 'brown',
        gridArea: '4 / 2 / 6 / 4',
    },
    tbd: {
        height: '200px',
        width: '420px',
        backgroundColor: 'white',
        gridArea: '4 / 4 / 6 / 6',
    },
}));

function Summary(props) {
    const auth = useAuth();
    const router = useRouter();
    const styles = useStyles();

    return (
        <Section
            bgColor={props.bgColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            {router.query.paid && auth.user.planIsActive && (
                <Box mx="auto" mb={4} maxWidth={400}>
                    <Alert severity="success">
                        You are now subscribed to the {auth.user.planId} plan
                        <span role="img" aria-label="party" style={{ marginLeft: '10px' }}>
                            🥳
                        </span>
                    </Alert>
                </Box>
            )}
            <div className={styles.container}>
                <div className={styles.navigation}>
                    <NavigationSection />
                </div>
                <div className={styles.slams}>
                    <Slams />
                </div>
                <div className={styles.progress}>hey</div>
                <div className={styles.tbd}>hey</div>
            </div>
        </Section>
    );
}

export default Summary;
